import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { collapseAction } from "../redux/reducers/sidebarReducer";

import { Layout, Menu } from 'antd';

import {
    HeartTwoTone,
    UserOutlined,
    SettingOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    ClockCircleOutlined,
    SelectOutlined,
} from '@ant-design/icons';

const { Header } = Layout;

const Navbar = () => {
    const collapsed = useSelector((state) => state.collapsed);
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState("1");
    const [disabled, setDisabled] = useState(false);
    const [notice, setNotice] = useState("The official social hour is over!");

    useEffect(() => {
        // figure out the key of the selected nav item based on the pathname of the current url
        updateSelectedKey(window.location.pathname);
        // calculate 
        showNotice();
        // fix this

        window.addEventListener("hashchange", function (e) {
            if (e.newURL.includes("#"))
                window.scrollTo(window.scrollX, window.scrollY - 66);
        });

        if(window.location.pathname.includes("chat"))
            setDisabled(true);
        else
            setDisabled(false);


        // update every minute
        const timer = setInterval(() => {
            showNotice();
        }, 60 * 1000);
        
    }, [location])

    const updateSelectedKey = (pathname) => {
        const navItems = ["/", "/rooms", "/settings", "/faq"];
        const newKey = navItems.indexOf(pathname) + 1;
        setSelectedKey(newKey.toString())
    }

    const toggle = () => {
        localStorage.setItem("collapsed", !collapsed);
        dispatch(collapseAction(collapsed));
    }

    const timeDiffCalc = (dateFuture, dateNow) => {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    
        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        // console.log('calculated days', days);

        // if the event is happening more than a day, just show absolute date 
        if(dateFuture - dateNow > 86400000)
            return "on " + dateFuture.toDateString().split(" ").splice(0,3).join(" ") 
        // a day before, show tomorrow
        else if(dateFuture.getDate() == dateNow.getDate()+1)
            return `tomorrow at ${dateFuture.getHours()}:${String(dateFuture.getMinutes()).padStart(2, '0')}`;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        console.log('calculated hours', hours);
    
        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        console.log('minutes', minutes);
    
        let difference = '';

        if (days > 0) {
          difference += (days === 1) ? `${days} day, ` : `${days} days, `;
        }
    
        if (hours > 0)
            difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
    
        difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`; 
    
        return "in " + difference;
      }

    const showNotice = () => {
        const today = new Date();
        // https://currentmillis.com
        const timestamps = {
                start: new Date(1733256000000), // 12/3 15:00
                end:   new Date(1733259600000), // 12/3 16:00
            };
        

        if (today >= timestamps.start && today <= timestamps.end) {
            setNotice("Coffee hour happening now! Reach out to others ");
        }
        else if (today < timestamps.start) {
            setNotice(`The next official social hour is ${timeDiffCalc(timestamps.start, today)}`);
        }
        else {
            setNotice(`The official social hours are over! See you next time.`);
        }
    }

    return (
        <Header className="px-0">
            <div className="fixed-navbar">
            <div className="logo" />
            <Menu className={disabled && "disabled"} mode="horizontal" selectedKeys={[selectedKey]}>
                <Menu.Item role="button" key="1" icon={<UserOutlined />}>
                    <NavLink exact={true} activeClassName="active" to="/" disabled={disabled}>People</NavLink>
                </Menu.Item>
                <Menu.Item role="button" key="2" icon={<VideoCameraOutlined />}>
                    <NavLink exact={true} activeClassName="active" to="/rooms" disabled={disabled}>Rooms</NavLink>
                </Menu.Item>
                <Menu.Item role="button" key="3" icon={<SettingOutlined />}>
                    <NavLink exact={true} activeClassName="active" to="/settings" disabled={disabled}>Settings</NavLink>
                </Menu.Item>
                <Menu.Item role="button" key="4" icon={<QuestionCircleOutlined />}>
                <NavLink exact={true} activeClassName="active" to="/faq" disabled={disabled}>FAQ</NavLink>
                </Menu.Item>

                <Menu.Item id="notice" role="button" key="100" icon={notice.includes("The next") ? <ClockCircleOutlined /> : <HeartTwoTone className='heart'/>}>
                    <a href="https://www.addevent.com/event/wD23852160" target="_blank" rel="noopener noreferrer">{notice} <SelectOutlined /></a>
                </Menu.Item>

                
            </Menu>
            {/* eslint-disable-next-line */}
            {React.createElement(collapsed ? MenuFoldOutlined : MenuUnfoldOutlined, {
                className: 'trigger',
                onClick: toggle,
                key: "4",
            })}
            </div>
        </Header>
    )
}

export default Navbar;